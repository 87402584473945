.floatingImages {
  position: absolute;
}

.floatingImageContainers {
  display: flex;
  z-index: 0;
}

#intro-container {
  position: absolute;
  /* padding-top: 20vh; */
  border-radius: var(--cardRadius);
  margin-top: -15%;
  align-items: center;
  display: flex;
  align-self: center;
  justify-content: space-around;
  max-width: var(--textMaxWidth);
  z-index: 1;
  /* flex-wrap:wrap; */
  /* justify-content:space-between;  */
}
