.sectionTitle {
  text-align: center;
  font-size: 8vw;
  margin-bottom: 2%;
  margin-top: 15%;
  /* z-index: 3; */
  /* position: relative; */
}

@media only screen and (max-width: 800px) {
  .projectContainer {
    /* margin:0 5%; */
    padding: 3%;
    margin: 3%;
    background-color: var(--projectbackground);
    flex-basis: 100%;
  }
}
@media only screen and (min-width: 801px) {
  .projectContainer {
    /* margin:0 5%; */
    padding: 2%;
    margin: 3%;
    background-color: var(--projectbackground);
    flex-basis: 43%;
  }
}
.allProjectsContainer {
  display: flex;
  flex-wrap: wrap;
  /* flex-flow: row wrap; */
  justify-content: space-between;
  /* align-items: left; */
}

.projectContainer {
  transition: all ease-in 0.5s;
  position: relative;
  transform: translateY(0px);
  display: flex;
  align-items: center;
  justify-content: center;
  /* border-radius: var(--cardRadius); */
  /* transition: opacity linear 0.5s; */
}
.projectOffScreen {
  opacity: 0%;
  transform: translateY(50px);
}
.projectOnScreen {
  opacity: 100%;
}

.card {
  background-color: transparent;
  border: none;
}

.card:hover {
  transition: box-shadow 0.4s cubic-bezier(0.58, 0.06, 0.61, 0.17);
  box-shadow: 0 0 10px rgb(49, 49, 49), -10px 5px 30px var(--topFaceHighlight),
    10px 5px 30px var(--topFaceHighlight);
}

.cardContainer {
  /* width: 1000px; */
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.cardBackground {
  width: 100%;
  position: relative;
  /* width:89VW; */
  /* padding-left: 10%; */
}

.cardImage {
  border-radius: var(--cardRadius) var(--cardRadius) 0 0;
  height: 100%;
}

.cardLayer {
  /* position: absolute; */
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  background-color: #0000004f;
  backdrop-filter: blur(10px);
  transition: opacity
    linear(
      0 0%,
      0 1.8%,
      0.01 3.6%,
      0.03 6.35%,
      0.07 9.1%,
      0.13 11.4%,
      0.19 13.4%,
      0.27 15%,
      0.34 16.1%,
      0.54 18.35%,
      0.66 20.6%,
      0.72 22.4%,
      0.77 24.6%,
      0.81 27.3%,
      0.85 30.4%,
      0.88 35.1%,
      0.92 40.6%,
      0.94 47.2%,
      0.96 55%,
      0.98 64%,
      0.99 74.4%,
      1 86.4%,
      1 100%
    )
    0.75s;
  flex-direction: column;
}

.cardLayer:hover {
  opacity: 1;
  /* display: block; */
}

.line {
  width: 100%;
  height: 2px;
  background-color: white;
  transition: width 0.5s;
}

.cardLayer:hover > .line {
  width: 50%;
}

.cardLayer > p,
h2,
a {
  text-align: center;
  color: white;
}

.cardLayer > a > h2 {
  margin: 5px;
  /* color: var(--floatAccent); */
  color: white;
}

.cardLayer > p {
  font-size: 1em;
  color: white;
  /* font-weight: 600; */
  margin: 0;
  padding: 0;
}

.cardOverlay .cardContainer .card {
  /* position: relative; */
  width: 100%;
}

.cardContainer .card .face {
  /* min-width:300px; */
  width: 100%;

  /* height: 200px; */
  transition: 0.4s;
}

.cardContainer .card .topFace {
  position: absolute;
  background: var(--topFaceColour);
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  z-index: 1;
  border-radius: 0 0 var(--cardRadius) var(--cardRadius);
  /* transform: translateY(115px) ; */
  transition: max-height 0.3s cubic-bezier(0.35, 0.06, 0.38, 0.95);
  /* transition:max-width 0.3s ease-out; */
  height: 250px;
  max-height: 230px;
  width: 100%;
  /* max-width: 100%; */
}

.cardContainer .card .topFace h2 {
  /* transform: scaleY(0.16666); */
}

.cardBackground:hover .cardContainer .card .topFace {
  /* transform: translateY(0); */
  /* transform: translateY(0); */
  max-height: 50px;
  /* min-height: 100%; */
  /* max-width: 250px; */
  box-shadow: inset 0 0 30px rgb(82, 82, 82),
    inset 20px 0 80px var(--topFaceHighlight),
    inset -20px 0 80px var(--topFaceHighlight),
    inset 20px 0 300px var(--topFaceHighlight),
    inset -20px 0 300px var(--topFaceHighlight);
}
.cardContainer .card:hover .topFace h2 {
  /* transform: scaleY(1); */
}

.cardContainer .card .topFace .content {
  opacity: 0.8;
  transition: 0.5s;
  text-align: center;
}

.cardBackground:hover .cardContainer .card .topFace .content {
  opacity: 1;
}

.cardContainer .card .topFace .content i {
  font-size: 3em;
  color: white;
  display: inline-block;
}

.cardContainer .card .topFace .content h2 {
  font-size: 2em;
  color: var(--topFaceTitle);
  text-align: center;
}

.cardContainer .card .topFace .content a {
  transition: 0.5s;
}

.cardContainer .card .face.face2 {
  position: relative;
  background: whitesmoke;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 20px 10px 20px;
  box-sizing: border-box;
  box-shadow: 0 10px 50px rgba(0, 0, 0, 0.8);
  height: 230px;
  border-radius: 0 0 var(--cardRadius) var(--cardRadius);
  /* transform: translateY(-100px); */
}

/* .cardContainer .card .face.face2 .content p,
a {
  font-size: 10pt;
  margin: 0;
  padding: 0;
  color: #333;
} */

/* .cardContainer .card .face.face2 .content a {
  text-decoration: none;
  color: black;
  box-sizing: border-box;
  outline: 1px dashed #333;
  padding: 10px;
  margin: 5px 0 0;
  display: inline-block;
  transition: box-shadow, background ease 0.3s;
} */

/* .cardContainer .card .face.face2 .content a:hover {
  background: var(--c1);
  color: whitesmoke;
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
} */

.projectImg {
  z-index: 0;
  width: 90%;
}
.projectH {
  color: white;
}

.covered {
  box-shadow: inset 0 0 #9158fd;
  transition: box-shadow 0.3s ease-in-out;
}

.unCover {
  transition: box-shadow 0.3s ease-in-out;
  box-shadow: inset 0 2000px #9158fd;
}

.cover {
  background: rgb(13, 62, 87);

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateY(100%);
  z-index: 1;
}

/* .card{
    background-color: #193245;
    width: 100%;
    margin: 1% 5%;
    padding:5%;
    width: 300px;
    height: 300px;
    position: relative;
} */

.envelopeContainer {
  margin: 1% -0.5%;
  height: 80px;
  width: 101%;
  background-color: var(--envelopeInside);
  /* overflow: hidden; */
}

.poliUnder {
  position: relative;
  transform: scaleY(0.6) translateY(-135%);
  /* width: 90%; */
}
.poliOver {
  position: relative;
  z-index: 1;
  /* width: 100%;
    transform: translateY(0%) translateX(0%); */
  /* box-sizing: 5px 5px 5px 5px var(--envelopeOutside); */
}
.envelope {
  background-image: linear-gradient(#282828 0%, var(--envelopeOutside) 25%);
  height: 100%;
  width: 100%;
}
/* .envelopeP2{
    position: absolute;
    background-color:  var(--envelopeOutside);
    height: 25px;
    width: 90%;
    border-bottom:3px solid black;
    transform: skewY(5deg) translateY(-240%) translateX(0%);
    z-index: 2;
}.envelopeP3{
    position: absolute;
    height: 20px;
    background-color:  var(--envelopeOutside);
    width: 50%;
    border-bottom:3px solid black;
    transform: skewY(-5deg) translateY(-248%) translateX(0%);
    z-index: 1;
}.envelopeP4{
    position: absolute;
    background-color:  var(--envelopeInside);
    height: 70px;
    width: 90%;
    transform: translateY(-40px) translateX(0%);
    z-index: 0;
} */
