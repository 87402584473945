.contactContainer {
  overflow: hidden;
}

.detectBottom {
  /* transform: translateY(-100px); */
}
#contact {
  width: 100%;
  height: 200px;
}

.contactTopCurve {
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: translateY(5px);
  z-index: 2;
}

.contactTopCurve svg {
  position: relative;
  display: block;
  width: calc(280% + 1.3px);
  height: 65px;
}

.contactTopCurve .shape-fill {
  fill: var(--floatAccent);
}

.float {
  position: fixed;
  overflow: hidden;
  background-color: var(--c1);
  text-align: center;
  box-shadow: 2px 2px 3px var(--floatAccent);
  transition: opacity 0.3s, width 0.3s, height 0.3s,
    border-radius 0.3s cubic-bezier(0.8, 0.16, 0.42, 0.89);
}
.floatTop {
  /* transform: translateY(140%); */
  border-radius: 50px;
  width: 60px;
  height: 60px;
  bottom: 20px;
  right: 20px;
  z-index: 10;
}
.floatGone {
  /* transform: translateY(140%) !important; */
  opacity: 0;
  z-index: 1;
  /* opacity:0; */
}
.floatBottom {
  width: 100%;
  height: 223px;
  /* padding-top: -100px; */
  /* position: relative; */
  position: absolute;
  display: flex;
  flex-direction: column;
}
.floatBottom h4 {
  opacity: 1;
  /* transform: translateX(33vw); */
  margin: auto;
}

.floatTop:hover {
  width: 130px;
  height: 180px;
  border-radius: 10px;
  text-align: center;
}
.my-float {
  margin-top: 22px;
}

.float h4 {
  width: 100px;
  height: 40px;
  font-size: medium;
  background-color: white;
  text-align: center;
  margin-bottom: 0.6%;

  border-radius: 40px;
  transition: opacity 0.4s linear;
  /* opacity: 0; */
}
.float h4:last-of-type {
  background-color: transparent;
  align-items: flex-start;
  box-shadow: none !important;
}
.floatTop h4 {
  margin: 0 0 5% 12.5%;
  opacity: 0;
}

.float h4:hover {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.floatTop:hover h4 {
  opacity: 1;
}
.floatTop .firstH4 {
  margin-top: -15%;
  font-size: 1.1rem;
  opacity: 0;
}
.floatTop:hover .firstH4 {
  margin-bottom: 5%;
  opacity: 1;
}
.floatBottom .firstH4 {
  margin-top: 0;
  font-size: 1.1rem;
  margin-bottom: 1%;
}
.attributions {
  width: 100px;
  height: 25px;
  margin: 0 10%;
  background-color: black;
  color: white;
  display: flex;
  flex-basis: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  position: absolute;
  bottom: 8px;
  cursor: pointer;
  /* margin: 0; */
  /* left: 0; */
  /* position: absolute; */
  /* transform: translate(0, -146px); */
}
.attributionsPopup {
  /* position: absolute; */
  display: none;
  padding: 3% 1%;
  bottom: 0;
  transform: translate(0, -160px);
  background-color: black;
  color: white;
  border-radius: 20px;
  min-width: 70vw;
  height: 150px;
}

.attributionsPopup a {
  color: white;
}

.attributionsContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

/* .attributionsContainer:active > .attributionsPopup {
  display: block;
} */
.attributionsPopupActive {
  display: block;
}

.innerContact {
  transform: translateY(9px);
}

.float .floatLinks {
  /* color: var(--floatAccent); */
  font-family: Articulat CF;
  font-style: normal;
  font-weight: 400;
  font-size: medium;
  /* text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25); */
  transition: opacity 0.3s linear;
  opacity: 0;
}
.floatTop:hover .floatLinks {
  opacity: 1;
}

.floatBottom .contactIcon {
  opacity: 0;
}
.contactIcon {
  width: 60px;
  height: 60px;
  margin-bottom: -50%;
  opacity: 1;
  transform: scale(0.8);
  transition: all 0.3s ease;
}
.floatTop:hover .contactIcon {
  transform: translateY(-60px);
  opacity: 0;
}
