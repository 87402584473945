.scrolling-lines {
  position: absolute;
  width: 100vw;
  overflow: hidden;
}

.back {
  z-index: -1;
}

.front {
  z-index: 0;
}

.scrolling-line-front {
  animation: scrollText 60s infinite linear;
}
.scrolling-line-back {
  animation: scrollText 60s infinite linear;
}
/* .scrolling-line:nth-child(2) {
  animation: scrollText2 10s infinite linear;
} */
/* .scrolling-line:nth-child(even){ */
/*   padding-left: 5%; */
/* } */

.noBorders {
  width: 0%;
}

.borders {
  width: 100%;
  height: 2px;
  background-color: white;
}

.hLTransform {
  transition: width 1s ease;
  margin: 0;
  z-index: 1;
  position: relative;
}

.scrolling-line span:hover {
  /* color: white; */
  /* transition: color 0.4s; */
  cursor: context-menu;
}
.scrolling-line span {
  color: transparent;
  -webkit-text-stroke: 0.5px white;
  font-size: 70px;
  /* font-family: "Poppins", sans-serif; */
  transition: color 0.4s;
  padding: 0 0.2em;
}

.scrolling-line-container {
  /* background-color: black; */
  background-color: var(--personalProjectFill);

  /* transform: rotateZ(-10deg); */
  overflow-x: hidden;
  white-space: nowrap;
  display: flex;
}

.scrolling-line-container-front {
  transform: skewY(-10deg);
}

.scrolling-line-container-back {
  transform: skewY(10deg);
}

/* .scrolling-line-container-back .scrolling-line {
  animation: scrollText2 40s infinite reverse;
}

.scrolling-line-container-front:nth-child(even) .scrolling-line {
  animation: scrollText2 40s infinite linear;
} */

@keyframes scrollText {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}
@keyframes scrollText2 {
  from {
    transform: translateX(-5%);
  }
  to {
    transform: translateX(-105%);
  }
}

#personal {
  /* display: flex;
  flex-wrap: wrap;
  /* height: 60vh; */
  /* align-items: center;
  justify-content: space-around; */
}
.personalTitlePanel {
  display: flex;
  flex-basis: 100%;
  height: 60vh;
  align-items: center;
  justify-content: space-between;
}

#personalContainer {
  background-color: var(--personalProjectFill);
  z-index: 3;
  position: relative;
}
.personalProjectContainer {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
}

.textTransform {
  transition: all 0.5s ease;
}
.leftOffScreen {
  transform: translateX(-15%);
  opacity: 0;
}
.onScreen {
  transform: translateX(0);
  opacity: 1;
}

.textPanel {
  width: 50%;
  font-size: 11vw;
  padding-left: 5%;
}
.carPanel {
  width: 100%;
  z-index: 3;
  margin: 0 5%;
  /* height: 100%; */
  /* height: 50%; */
  /* touch-action: none; */
  /* max-width: 50%; */
  /* margin-left:5%; */
  /* position: fixed;
    top:50px; */
}
.carPanel > div {
  height: 100%;
  display: flex;
  justify-content: center;
}

@media only screen and (min-width: 800px) {
  .carPanel > div > img {
    height: 100%;
    width: auto;
  }
  .carPanel {
    height: 100%;
  }
}

/* @media only screen and (max-width: 800px) {
  .carPanel {
    height: 25%;
    width: 50%;
  }
} */
/* @media only screen and (min-width: 661px) {
  .carPanel {
    width: 100%;
    height: 50%;
  }
} */
@media only screen and (max-width: 800px) {
  /* .textPanel {
    padding-left: 5%;
  } */
}

/* @media only screen and (min-width: 801px) {
  .textPanel {
   
  }
  #personal {
    margin: 0 8%;
  }
} */

.threeDCanvas {
  width: 100%;
  height: 100%;
  display: block;
  touch-action: auto !important;
}

.curveTop {
  /* position: absolute;
    bottom: 0;
    left: 0; */
  /* background-color: var(--white); */
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg) translateY(101px);
}
@media only screen and (max-width: 395px) {
  .curveTop {
    /* background-color: var(--white); */
  }
}

.curveBottom {
  /* position: absolute; */
  /* top: 0;
    left: 0; */
  width: 100%;
  overflow: hidden;
  line-height: 0;
  /* transform: translateY(101px); */
}

.curveBottom svg,
.curveTop svg {
  position: relative;
  display: block;
  width: calc(280% + 1.3px);
  height: 106px;
}
/* .curveTop svg {
    position: relative;
    display: block;
    width: calc(280% + 1.3px);
    height: 106px;
} */

.curveBottom .shape-fill,
.curveTop .shape-fill {
  fill: var(--personalProjectFill);
}
