.imgNoDrawBorders {
  --c: white; /* the border color */
  --b: 2px; /* the border thickness*/
  --g: 0px; /* the gap on hover */
  --t: 1s;

  padding: calc(var(--g) + var(--b));
  --_g: #0000 25%, var(--c) 0;
  background: conic-gradient(
        from 180deg at top var(--b) right var(--b),
        var(--_g)
      )
      var(--_i, 200%) 0 /200% var(--_i, var(--b)) no-repeat,
    conic-gradient(at bottom var(--b) left var(--b), var(--_g)) 0
      var(--_i, 200%) / var(--_i, var(--b)) 200% no-repeat;
  transition: var(--t), background-position var(--t) var(--t);
}

.imgDrawBorders {
  --_i: 100%;
  transition: var(--t), background-size var(--t) var(--t);
}
