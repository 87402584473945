:root {
  /* --siteBackground: #212529; */
  --siteBackground: #2c3f51;
}
canvas {
  z-index: 0;
}

.raminPhoto {
  /* I trully have no idea why I have to make the height 30% too but otherwise the photo gets
    all skinny */
  width: 30%;
  min-width: 30%;
  max-height: 50vh;
  max-height: 30%;
  flex-basis: 30%;
  z-index: 0;
  justify-content: center;
  display: flex;
  overflow: hidden;
  margin: 3% 1% 3% 0;
}

.raminPhoto > img {
  max-height: 50vh;
  width: auto;
  max-width: 100%;
  /* align-self: center; */
  border-radius: var(--cardRadius);
  /* height: 100%; */
}

.raminOverlap {
  grid-row: 1/-1;
  grid-column: 1/-1;
}

#intro {
  height: 100vh;
  /* margin-bottom: 10vh; */
  display: flex;
  justify-content: center;
  position: relative;
  /* background-color: var(--personalProjectFill); */
}

.intro-background {
  background-image: url("../assets/spaceBG.jpeg");
  background-size: cover;
  background-position: center;
}

.frost {
  background: rgba(0, 0, 0, 0.3);
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .frost {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }
}

.card-container {
  display: flex;
  justify-content: center;
}

.inner-card-container {
  max-width: 1500px;
}

.aboutDiv {
  min-height: 50vh;
  margin-top: -88px;
}
.whiteBackground {
  background: var(--white);
}
#aboutSecContainer {
  /* transform: translateY(10px); */
  /* margin-bottom: -10%; */
  z-index: -1;
}
.aboutMe {
  /* font-size: 4vw; */
  /* font-size: 1.5rem; */
  z-index: 1;
  /* flex-basis:70%; */
}
.introText {
  font-weight: 500;
  font-size: 1.5rem;
}
.aboutText {
  /* font-size: 3vw; */
  font-size: 1.3rem;
  color: black;
}
.aboutTitle {
  /* padding-top: 10%; */
  color: black;
}
#aboutContainer {
  padding: 0 5%;
  width: 80%;
  max-width: var(--textMaxWidth);
}
.fillerBox {
  height: 88px;
  /* margin-bottom: -88px; */
}
@media only screen and (max-width: 800px) {
  #intro-container {
    /* display: inline-block; */
  }
  .aboutMe {
    padding: 0 5% 0 5%;
  }
  #aboutContainer {
    padding: 0 5%;
    width: 100%;
  }
}
@media only screen and (min-width: 801px) {
  #intro-container {
    margin: -15% 10% 0 10%;
    /* display: inline-block; */
  }
  .aboutMe {
    padding-right: 5%;
    max-width: 400px;
  }
}
.intro {
  background: black;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
.slider {
  background: var(--c1);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateY(100%);
  z-index: 10;
}

.intro-text {
  color: rgb(233, 233, 233);
  font-family: "Heebo", sans-serif;
  font-size: 6rem;
}

.hide {
  background: black;
  overflow: hidden;
  font-size: 3rem;
}

.hide span {
  transform: translateY(100%);
  display: inline-block;
}

#projects {
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
