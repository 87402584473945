/* @import url("https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@400;600;700&display=swap");

:root {
  /* Green Palette */
  /* --envelopeOutside:#0f1e29;
    --envelopeInside:#193245;
    --floatAccent: #b7d5d4;

    --c1:#b7d5d4;
    --c2:#b7d5d4;
    --projectbackground:#305252;
    --bodyBackground:#373e40;
    --topFaceColour:#488286; */

  /* idk new one */
  --envelopeOutside: #72efdd;
  --envelopeInside: #72efdd;
  --floatAccent: #ff5964;

  --white: #f0ffff;

  --c1: #ff5964;
  --c2: #ff5964;
  /* --projectbackground: #93b7be; */
  --projectbackground: #b2cde4;

  /* --bodyBackground: #0b2027; */
  --bodyBackground: #2c3f51;
  --topFaceColour: #93b7be;
  /* --topFaceHighlight:#00509D; */
  --topFaceHighlight: #f1fffa;

  --topFaceTitle: black;

  /* --personalProjectFill: #0A0304; */
  --personalProjectFill: #181830;

  --textMaxWidth: 1400px;

  /* --cardRadius: 22px; */
  --cardRadius: 0px;
}
* {
  -ms-overflow-style: none;
  box-sizing: border-box;
}
::-webkit-scrollbar {
  display: none;
}
html {
  /* scroll-behavior: smooth; */
  /* overflow-y:hidden; */
}
body {
  background-color: var(--bodyBackground);
}
p {
  color: azure;
  font-weight: 400;
  font-size: 2rem;
}
h1 {
  color: azure;
  font-weight: 800;
  font-size: 2rem;
}
img {
  width: 100%;
}
.wave {
  /* position: absolute; */
  /* bottom: 0;
    left: 0; */
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg) translateY(99%);
  /* margin-top:-6%;  */
  margin-bottom: 0%;
}

.wave svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 102px;
  transform: rotateY(180deg);
}

.wave .shape-fill {
  fill: var(--white);
}

.researchTitle {
  color: aliceblue;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(-90deg);
  margin-left: -50%;
}

.cursor {
  width: 20px;
  height: 20px;
  border: 1px solid white;
  border-radius: 50%;
  position: absolute;
  transition-duration: 200ms;
  transition-timing-function: ease-out;
  animation: cursorAnim 0.5s infinite alternate;
  pointer-events: none;
}

.cursor::after {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  border: 8px solid gray;
  border-radius: 50%;
  opacity: 0.5;
  top: -8px;
  left: -8px;
  animation: cursorAnim2 0.5s infinite alternate;
}

@keyframes cursorAnim {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.7);
  }
}

@keyframes cursorAnim2 {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.4);
  }
}

@keyframes cursorAnim3 {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(3);
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
