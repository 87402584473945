.nav {
  position: fixed;
  width: 100%;
  z-index: 4;
}

.navBar {
  position: sticky;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  align-items: center;
  justify-content: flex-start;
  padding: 1.4rem 1rem;
}

/* mobile phone css */
@media only screen and (max-width: 600px) {
  .navBar {
    justify-content: center;
  }
}

.navbarUl {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: table;
  text-align: center;
}

.navItem {
  display: table-cell;
  position: relative;
  cursor: pointer;
}

.navLink {
  z-index: 10;
}

.navItem .navLink {
  color: white !important;
  border: 0px;
  padding: 10px;
  font-size: 1em;
}
.navLink:after {
  background: none repeat scroll 0 0 transparent;
  bottom: -1;
  content: "";
  display: block;
  height: 0.11em;
  opacity: 0;
  left: 50%;
  position: absolute;
  background: #fff;
  /* transition: width 0.3s ease 0s, left 0.3s ease 0s; */
  -webkit-transition: width 0.4s cubic-bezier(0.22, 0.67, 0.29, 0.95),
    left 0.4s cubic-bezier(0.22, 0.67, 0.29, 0.95) 0s;
  transition: width 0.4s cubic-bezier(0.22, 0.67, 0.29, 0.95),
    left 0.4s cubic-bezier(0.22, 0.67, 0.29, 0.95) 0s, opacity 0.4s ease-in-out;
  width: 0;
}
.navItem .navLink:hover {
  color: white;
  text-decoration: none;
}
.navItem .navLink:hover:after {
  width: 100%;
  opacity: 1;
  left: 0;
}
